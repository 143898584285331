<template>
  <CriaTip />
</template>

<script>
import CriaTip from "@/components/tipologias/CriaTipologia.vue";

export default {
  components: {
    CriaTip
  }
};
</script>
